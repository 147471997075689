import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import TypeAnimation from "react-type-animation";
import "~styles/pages/services/_support.scss";

const ContentPage = () => (
  <Layout>
    <SEO
      title="Webmaster & Marketing IT Support"
      description="We provide stress-free Webmaster & Marketing IT Support. Submit a ticket, and we’ll fix the problem. Simple. "
    />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={[
            "Webmaster +",
            500,
            "Webmaster + Marketing IT Support",
            500,
          ]}
          className={"title"}
          wrapper="h1"
        />
        <div className="p-mb">
          <p>
            You have a website (or at least you think you do), but when it comes
            to knowing about your hosting, SSL certificate, domain, DNS manager,
            email server, CPanel, FTP server – your eyes are more glazed than a
            Tim Horton's Old-Fashioned Donut.
          </p>
          <p>
            Let me put it this way. Would you rather sit on the phone with
            GoDaddy for 4 hours, or would you rather us do it (more importantly,
            why are you still hosting with them?)?
          </p>
          <p>
            You should be spending time counting sales and converting leads, and
            anything related to the management of your website and/or marketing
            tech should be assigned to us. Remove IT Manager from your job title
            and focus on making money instead.
          </p>
          <p>
            We take the stress and anxiety out of the equation. Submit a ticket,
            and we’ll fix the problem. Simple.
          </p>
        </div>
      </div>
    </section>
    <section className="web-it-support">
      <div className="container">
        <Products>
          <div
            title="Monthly Retainer"
            subtitle="$1,125 CAD/Month"
            id="support-monthly"
            extrainfo="10 + 5 Hours/Month"
          >
            <p>
              Includes up to 10 hours of support for any and all email, website,
              hosting, CRM, social technical issues and glitches that can be
              handled remotely + 5 hours of proactive website management to keep
              your business engine humming smoothly and prevent as many minor +
              major issues from arising as possible.
            </p>
          </div>
          <div
            title="Quarterly Retainer"
            subtitle="$4,500 CAD/Quarter "
            id="support-quarterly"
            extrainfo="10 + 5 Hours/Month"
          >
            <p>
              Includes up to 15 hours of support per month, for all email,
              website, hosting, CRM and social technical issues and glitches
              that can be handled remotely. Also includes 5 hours per month of
              proactive website management, update requests and changes to keep
              your business engine looking fresh and humming smoothly to prevent
              as many minor + major issues from arising as possible.{" "}
            </p>
          </div>
          <div
            title="Emergency Hourly Support"
            subtitle="$250 CAD/Hour"
            id="support-emergency"
          >
            <p>
              Minimum of a 2-hour booking to assess and provide (hopefully) a
              solution, or at least an outline of the work needed, which may
              lead to additional hours needing to be purchased.
            </p>
          </div>
        </Products>
        <h2 className="support-header">Support Add-Ons </h2>
        <Products type="outline1">
          <div
            title="Additional Hourly Support"
            subtitle="$175 CAD/Hour"
            id="support-additional"
            extrainfo="This price is only available if you already have a support retainer with us."
          >
            <p>
              Purchased in 3-hour blocks. These will rollover if they are unused
              and are always used last within a given month (so as to not eat
              into your support package hours).{" "}
            </p>
          </div>
        </Products>
      </div>
    </section>
  </Layout>
);

export default ContentPage;
